import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import {ThemeProvider} from '@material-ui/core/styles';

import {MsalProvider} from "@azure/msal-react";
import {Configuration, PublicClientApplication} from "@azure/msal-browser";

import './index.css';
import App from './clientSide/components/App';
import {ClientConfigContext} from "./clientSide/clientContext";
import {ClientConfigType} from './types/clientConfigTypes';

import i18n from "i18next";
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {fetchClientConfiguration} from "./clientSide/services/fetchClientConfig";
import {OPENID_PARAMS} from "./types/openid_enum";
import {ClientLogger} from '@Praia-Health/spi-common-utils/dist/logging/clientLogger';
import {ApiRequestLogger} from "./clientSide/logger/apiRequestLogger";
import {AmplitudeClientConfig} from '@Praia-Health/spi-amplitude-client/dist/types/amplitudeConfig';
import {initAmplitude} from './clientSide/services/amplitudeClient';
import getTheme from './clientSide/util/getTheme';
import {fetchOidcState} from "./clientSide/util/oidcStateExtractUtils";
import { getClientCorrelationId } from './clientSide/CorrelationIDSession';

const searchParams = new URLSearchParams(document.location.search);
const brand = fetchBrand();
const isMobile = searchParams.get('deviceType') === 'true';
const amplitudeDeviceId = searchParams.get(OPENID_PARAMS.AMPLITUDE_DEVICE_ID) || undefined;
const amplitudeSessionId = searchParams.get(OPENID_PARAMS.AMPLITUDE_SESSION_ID) || undefined;

// Brand info may come from the request parameter or oidc state
function fetchBrand(): string {
    let brand: string = searchParams.get(OPENID_PARAMS.BRAND) as string;
    if (!brand) {
        try {
            const oidcState = fetchOidcState();
            brand = oidcState?.brand as string;
        } catch (err: any) {
            console.log("no oidc state");
        }
    }
    return brand;
}

function initI18N(clientConfig: ClientConfigType) {
    i18n
        // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
        // learn more: https://github.com/i18next/i18next-http-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: {
                'espanol': ['es'],
                'default': ['en']
            },
            keySeparator: false,
            debug: false,
            initImmediate: true,
            defaultNS: 'translation',
            backend: {
                loadPath: `${clientConfig.i18nPath || ""}/locales/{{lng}}/{{ns}}.json`,
                crossDomain: true,
                customHeaders: {
                    'correlation-id': getClientCorrelationId()
                }
            },
            detection: {
                order: ['querystring', 'cookie', 'localStorage', 'navigator'],

                lookupQuerystring: 'lang',
                lookupCookie: 'i18n',
                lookupLocalStorage: 'i18nextLng',

                caches: ['localStorage', 'cookie'],
                excludeCacheFor: ['cimode'],

                cookieMinutes: 10
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            }
        });
}

// Initialize PublicClientApplication.Configuration
function renderAppWithConfiguration(response: AxiosResponse) {
    console.log("Render App With Configuration");
    const clientConfig: ClientConfigType = response.data;
    // Determine Branding By Configurations
    let branding = clientConfig.brandConfigs.find((brandConf) => {
        return brandConf.brand.toLowerCase() === brand?.toLowerCase();
    });
    if (!branding) { // use default
        branding = clientConfig.brandConfigs.find((brandConf) => {
            return brandConf.default;
        })
    }

    if (!branding) {
        throw new Error("No branding found for the given brand");
    }

    const signInPolicy = clientConfig.b2cTenantConfig.signInPolicies.find((policy) => {
        return policy.name === branding?.signInPolicy
    });

    // Initializing MSAL Configuration
    const azureHost = `${clientConfig.b2cTenantConfig.tenantName}.b2clogin.com`;
    const signInAuthority = `https://${azureHost}/${clientConfig.b2cTenantConfig.tenantId}/${signInPolicy?.policyId}`;
    const config: Configuration = {
        auth: {
            authority: signInAuthority,
            knownAuthorities: [azureHost],
            clientId: clientConfig.b2cTenantConfig.clientId,
            redirectUri: clientConfig.b2cTenantConfig.redirectUri,
            authorityMetadata: clientConfig.authorityMetadata
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        },
        system: {
            allowRedirectInIframe: true
        }
    };

    initI18N(clientConfig);

    // Branded Theme/UI/Background Change
    const theme = getTheme(branding.theme);
    const backgroundCss = (branding?.backgroundImage) ? `url("${clientConfig.cdnPath}${branding?.backgroundImage}")` : branding?.backgroundColor;
    document.getElementsByTagName('html')[0].style.background = `${backgroundCss} no-repeat center / cover`;
    const favIcon = document.getElementById("favicon");
    if (favIcon) {
        favIcon.setAttribute("href", clientConfig.cdnPath + "/brandeduisignupsignin/images/favicon.ico");
    }

    //Initialize Amplitude related configs
    const maxMessageLength = clientConfig?.loggingConfig?.maxMessageLength;
    const logger: ClientLogger = new ApiRequestLogger({}, maxMessageLength);
    const ampConfig: AmplitudeClientConfig = {
        logger,
        authUrl: clientConfig.amplitudeConfig.authUrl,
        noAuthUrl: clientConfig.amplitudeConfig.noAuthUrl,
        enableAmplitudeEvents: clientConfig.amplitudeConfig.enableEvents,
    };
    initAmplitude(ampConfig, brand, isMobile, amplitudeDeviceId, amplitudeSessionId);
    const pca = new PublicClientApplication(config);

    ReactDOM.render(
        <React.StrictMode>
            <ClientConfigContext.Provider value={clientConfig}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <Suspense fallback="loading">
                            <MsalProvider instance={pca}>
                                <App/>
                            </MsalProvider>
                        </Suspense>
                    </ThemeProvider>
                </BrowserRouter>
            </ClientConfigContext.Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

if (window.location.href.includes("/logout")) {
    // [SPI-3821] The single sign out feature loads this logout endpoint in an IFrame.
    // It has a short time window for the following code to load and execute.
    // Do not add lengthy running code in this.
    localStorage.clear();
    sessionStorage.clear();
} else {
    fetchClientConfiguration(renderAppWithConfiguration, brand || undefined);
}
